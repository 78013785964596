
import { defineComponent, ref } from 'vue'
import ServicesByocFilter from '@/components/pages/services/byoc/list/ServicesByocFilter.vue'
import SenderIdTable from '@/components/pages/chats/settings/byoc/senderId/SenderIdTable.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import { SendersIdMock } from '@/definitions/services/byoc/data'

export default defineComponent({
  components: {
    ServicesByocFilter,
    SenderIdTable,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Sender ID', value: 'sender-id-slot' },
      { text: 'Calls', value: 'voice-slot', width: '10%' },
      { text: 'SMS', value: 'sms-slot', width: '10%' },
      { text: 'MMS', value: 'mms-slot', width: '20%' },
      { text: 'Provider', value: 'provider-slot' },
    ])
    const tableItems = ref(SendersIdMock)

    return {
      isEmptyMode,
      search,
      tableHeaders,
      tableItems,
    }
  },
})
