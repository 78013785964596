
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import SenderIdTableDropdown from '@/components/pages/chats/settings/byoc/senderId/SenderIdTableDropdown.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import Highlight from '@/components/shared/Highlight.vue'

export default defineComponent({
  components: {
    TmTable,
    TmStatus,
    TmTooltip,
    SenderIdTableDropdown,
    Highlight,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    showSelection: {
      type: Boolean,
      default: true,
    },
  },
})
