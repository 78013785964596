import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_services_byoc_filter = _resolveComponent("services-byoc-filter")!
  const _component_sender_id_table = _resolveComponent("sender-id-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_services_byoc_filter, {
      search: _ctx.search,
      "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      "search-placeholder": "Search Sender IDs",
      class: "mb-4"
    }, null, 8, ["search"]),
    _createVNode(_component_sender_id_table, {
      headers: _ctx.tableHeaders,
      items: _ctx.tableItems,
      "items-name": "numbers",
      "show-link": ""
    }, null, 8, ["headers", "items"])
  ]))
}