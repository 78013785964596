
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    TopFilter,
  },
})
